import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import logos from '../assets/img/logo/logo.png'
import {HiMenuAlt3} from "react-icons/hi";
import {AiOutlineClose} from "react-icons/ai";
import {MenuData} from './MenuData'
import NavItem from './NavItems'
import {FiChevronDown} from "react-icons/fi";

// image
import megamenu1 from '../assets/img/mega-menu/timekeeping.svg'
import megamenu2 from '../assets/img/mega-menu/reporting.svg'
import megamenu3 from '../assets/img/mega-menu/planning.svg'
import megamenu4 from '../assets/img/mega-menu/budgeting.svg'
import megamenu5 from '../assets/img/mega-menu/attendance.svg'
import megamenu6 from '../assets/img/mega-menu/payroll.svg'
import megamenu7 from '../assets/img/mega-menu/freelancers.svg'
import megamenu8 from '../assets/img/mega-menu/agencies.svg'
import megamenu9 from '../assets/img/mega-menu/lawyers.svg'
import megamenu10 from '../assets/img/mega-menu/startups.svg'
import megamenu11 from '../assets/img/mega-menu/developers.svg'
import megamenu12 from '../assets/img/mega-menu/consultants.svg'
import megamenu13 from '../assets/img/mega-menu/accountants.svg'
import megamenu14 from '../assets/img/mega-menu/construction.svg'
import megamenu15 from '../assets/img/mega-menu/plan-free.svg'
import megamenu16 from '../assets/img/mega-menu/plan-pro.svg'
import megamenu17 from '../assets/img/mega-menu/plan-enterprise.svg'
import megamenu18 from '../assets/img/mega-menu/plan-compare.svg'
import megamenu19 from '../assets/img/mega-menu/contact-us.svg'
import megamenu20 from '../assets/img/mega-menu/tutorials.svg'
import megamenu21 from '../assets/img/mega-menu/knowledge-hub.svg'
import megamenu22 from '../assets/img/mega-menu/blog.svg'
import megamenu23 from '../assets/img/mega-menu/help.svg'
import megamenu24 from '../assets/img/mega-menu/stars.svg'

import timer from '../assets/img/mega-menu/timer.svg'
import timesheet from '../assets/img/mega-menu/timesheet.svg'
import kiosk from '../assets/img/mega-menu/kiosk.svg'
import calendar from '../assets/img/mega-menu/calendar.svg'
import autotracker from '../assets/img/mega-menu/autotracker.svg'
import reports from '../assets/img/mega-menu/reports.svg'
import rates from '../assets/img/mega-menu/rates.svg'
import progress from '../assets/img/mega-menu/progress.svg'
import activity from '../assets/img/mega-menu/activity.svg'
import location from '../assets/img/mega-menu/location.svg'
import scheduling from '../assets/img/mega-menu/scheduling.svg'
import timeoff from '../assets/img/mega-menu/timeoff.svg'
import approval from '../assets/img/mega-menu/approval.svg'
import expenses from '../assets/img/mega-menu/expenses.svg'
import invoicing from '../assets/img/mega-menu/invoicing.svg'


const Header = () => {
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e)=>{
        const header = document.querySelector('.navbar-area');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };
    const [click, setClick] = useState(false);

    const handleClick = () => {
        if (click) {
            document.querySelector("#navbarSupportedContent").classList.remove("d-block")
        } else {
            document.querySelector("#navbarSupportedContent").classList.add("d-block")
        }
        setClick(!click);
    }

    const submenuProductClick = () => {
        let element = document.querySelector(".dropdown-menu-product");
        element.classList.toggle("sub-menu-show");

        //
        let elementFeatures = document.querySelector(".dropdown-menu-features");
        elementFeatures.classList.remove("sub-menu-show");
    }
    const submenuFeaturesClick = () => {
        let element = document.querySelector(".dropdown-menu-features");
        element.classList.toggle("sub-menu-show");

        //
        let elementProduct = document.querySelector(".dropdown-menu-product");
        elementProduct.classList.remove("sub-menu-show");
    }

    return (
        <>
            <div className="navbar-area">
                <div className="plamb-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link className="navbar-brand" to="/">
                                <img src={logos} style={{height: '60px'}} className="white-logo" alt="logo"/>
                                <img src={logos} style={{height: '60px'}} className="black-logo" alt="logo"/>
                                {/* <h2><Link to="/">eClock.Work</Link></h2> */}
                            </Link>
                            <div className="mean-menu" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    {/* {
                                        MenuData.map((item, index) => (
                                            <NavItem item={item} key={index}/>
                                        ))
                                    } */}
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item has-children">
                                        <Link className="nav-link" to="/product">
                                            Product
                                            <i><FiChevronDown/></i>
                                        </Link>
                                        <i className="mobile-link" onClick={submenuProductClick}><FiChevronDown/></i>
                                        <ul className="dropdown-menu dropdown-menu-product">
                                            <div className="submenu sitewidth">                    
	                                            <div className="menu__column mw-455">             
                                                    <div className="column-heading">              
                                                        <h5>USE CASES</h5>           
                                                    </div>             
                                                    <div className="menu__items-wrapper">              
                                                        <Link to="/timekeeper" className="menu__item">               
                                                            <div className="item__icon">               
                                                                <img src={megamenu1} alt="img" />            
                                                            </div>          
                                                            <div className="item__text">           
                                                                <h5 className="item__title">Timekeeping</h5>                 
                                                                <p className="item__desc">Track time using a timer, timesheet, or kiosk.</p>         
                                                            </div>            
                                                        </Link>              
                                                        <Link to="/product_homereporting" className="menu__item">                 
                                                            <div className="item__icon">                 
                                                                <img src={megamenu2} alt="img" />          
                                                            </div>            
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Reporting</h5>           
                                                                <p className="item__desc">See where time goes and analyze costs.</p>        
                                                            </div>          
                                                        </Link>          
                                                        <Link to="/planning" className="menu__item">             
                                                            <div className="item__icon">           
                                                                <img src={megamenu3} alt="img" />          
                                                            </div>         
                                                            <div className="item__text">             
                                                                <h5 className="item__title">Planning</h5>              
                                                                <p className="item__desc">Manage resources, capacity, and utilization.</p>           
                                                            </div>       
                                                        </Link>            
                                                        <Link to="/product_budgeting" className="menu__item">               
                                                            <div className="item__icon">              
                                                                <img src={megamenu4} alt="img" />           
                                                            </div>            
                                                            <div className="item__text">                
                                                                <h5 className="item__title">Budgeting</h5>           
                                                                <p className="item__desc">Set client budgets and track project status.</p>       
                                                            </div>          
                                                        </Link>         
                                                        <Link to="/productattendance" className="menu__item">          
                                                            <div className="item__icon">              
                                                                <img src={megamenu5} alt="img" />        
                                                            </div>          
                                                            <div className="item__text">           
                                                                <h5 className="item__title">Attendance</h5>          
                                                                <p className="item__desc">Track daily employee attendance and overtime.</p>        
                                                            </div>      
                                                        </Link>            
                                                        <Link to="/productpayroll" className="menu__item">            
                                                            <div className="item__icon">              
                                                                <img src={megamenu6} alt="img" />     
                                                            </div>        
                                                            <div className="item__text">          
                                                                <h5 className="item__title">Payroll</h5>           
                                                                <p className="item__desc">Calculate payroll and employee costs.</p>         
                                                            </div>       
                                                        </Link>        
                                                    </div>        
	                                            </div>       
                                                <div className="menu__column mw-328 industries">         
                                                    <div className="column-heading">            
                                                        <h5>INDUSTRIES</h5>       
                                                    </div>         
                                                    <div className="menu__items-wrapper">               
                                                        <Link to="/productindustries" className="menu__item">               
                                                            <div className="item__icon">                
                                                                <img src={megamenu7} alt="img" />      
                                                            </div>           
                                                            <div className="item__text">               
                                                                <h5 className="item__title">Freelancers</h5>           
                                                            </div>           
                                                        </Link>            
                                                        <Link to="/productdevelopers" className="menu__item">              
                                                            <div className="item__icon">               
                                                                <img src={megamenu11} alt="img" />            
                                                            </div>           
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Developers</h5>          
                                                            </div>         
                                                        </Link>         
                                                        <Link to="/productagencies" className="menu__item">             
                                                            <div className="item__icon">         
                                                                <img src={megamenu8} alt="img" />         
                                                            </div>      
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Agencies</h5>            
                                                            </div>         
                                                        </Link>          
                                                        <Link to="/ProductConsultants" className="menu__item">              
                                                            <div className="item__icon">               
                                                                <img src={megamenu12} alt="img" />          
                                                            </div>           
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Consultants</h5>         
                                                            </div>    
                                                        </Link>         
                                                        <Link to="/productlawyer" className="menu__item">        
                                                            <div className="item__icon">           
                                                                <img src={megamenu9} alt="img" />         
                                                            </div>           
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Lawyers</h5>            
                                                            </div>          
                                                        </Link>           
                                                        <Link to="/product_indaccountants" className="menu__item">              
                                                            <div className="item__icon">          
                                                                <img src={megamenu13} alt="img" />         
                                                            </div>          
                                                            <div className="item__text">            
                                                                <h5 className="item__title">Accountants</h5>           
                                                            </div>          
                                                        </Link>             
                                                        <Link to="/productstartup" className="menu__item">                
                                                            <div className="item__icon">              
                                                                <img src={megamenu10} alt="img" />          
                                                            </div>               
                                                            <div className="item__text">                
                                                                <h5 className="item__title">Startups</h5>             
                                                            </div>            
                                                        </Link>              
                                                        <Link to="/Product_construction" className="menu__item">               
                                                            <div className="item__icon">            
                                                                <img src={megamenu14} alt="img" />          
                                                            </div>               
                                                            <div className="item__text">             
                                                                <h5 className="item__title">Construction</h5>           
                                                            </div>            
                                                        </Link>             
                                                    </div>              
                                                    <div className="additional-info">                 
                                                        <Link to="/product_MoreInd">More Industries<span>→</span></Link>            
                                                    </div>           
                                                </div>            
                                                <div className="menu__column mw-233 plans after-parent">               
                                                    <div className="column-after hidden-sm"></div>              
                                                    <div className="column-heading">                  
                                                        <h5>PLANS</h5>             
                                                    </div>                 
                                                    <div className="menu__items-wrapper">               
                                                        <Link to="/product_freeplan" className="menu__item">                  
                                                            <div className="item__icon no-hover">               
                                                                <img src={megamenu15} alt="img" />             
                                                            </div>                
                                                            <div className="item__text">                   
                                                                <h5 className="item__title">FREE</h5>                 
                                                                <p className="item__desc">Time Tracking</p>              
                                                            </div>               
                                                        </Link>              
                                                        <Link to="/product_proplan" className="menu__item">                   
                                                            <div className="item__icon no-hover">                  
                                                                <img src={megamenu16} alt="img" />             
                                                            </div>                 
                                                            <div className="item__text">                  
                                                                <h5 className="item__title">PRO</h5>                 
                                                                <p className="item__desc">Profit &amp; Productivity</p>             
                                                            </div>               
                                                        </Link>                
                                                        {/* <Link to="/#!" className="menu__item">                
                                                            <div className="item__icon no-hover">                
                                                                <img src={megamenu17} alt="img" />        
                                                            </div>             
                                                            <div className="item__text">                
                                                                <h5 className="item__title">ENTERPRISE</h5>               
                                                                <p className="item__desc">Security &amp; Privacy</p>            
                                                            </div>             
                                                        </Link>               */}
                                                        <Link to="/product_seepricingplan" className="menu__item no-border">                
                                                            <div className="item__icon no-hover">                 
                                                                <img src={megamenu18} alt="img" />            
                                                            </div>              
                                                            <div className="item__text">                
                                                                <h5 className="item__title">See Pricing</h5>              
                                                            </div>            
                                                        </Link>              
                                                    </div>            
                                                </div>              
	                                            <div className="background-blur"></div>           
                                            </div>
                                        </ul>
                                    </li>
                                    <li className="nav-item has-children">
                                        <Link className="nav-link" to="/#!">
                                            Features
                                            <i><FiChevronDown/></i>
                                        </Link>
                                        <i className="mobile-link" onClick={submenuFeaturesClick}><FiChevronDown/></i>
                                        <ul className="dropdown-menu dropdown-menu-features">
                                            <div className="submenu sitewidth features-mega-menu">                    
	                                            <div className="menu__column mw-455">             
                                                    <div className="column-heading">              
                                                        <h5>TIMEKEEPING</h5>           
                                                    </div>             
                                                    <div className="menu__items-wrapper">              
                                                        <Link to="/Timer" className="menu__item">               
                                                            <div className="item__icon">               
                                                                <img src={timer} alt="img" />            
                                                            </div>          
                                                            <div className="item__text">           
                                                                <h5 className="item__title">Timer</h5>                 
                                                            </div>            
                                                        </Link>              
                                                        <Link to="/timesheet" className="menu__item">                 
                                                            <div className="item__icon">                 
                                                                <img src={timesheet} alt="img" />          
                                                            </div>            
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Timesheet</h5>               
                                                            </div>          
                                                        </Link>          
                                                        {/* <Link to="/features_timekeepingkiosk" className="menu__item">             
                                                            <div className="item__icon">           
                                                                <img src={kiosk} alt="img" />          
                                                            </div>         
                                                            <div className="item__text">             
                                                                <h5 className="item__title">Kiosk</h5>                        
                                                            </div>       
                                                        </Link>            
                                                        <Link to="/calendar" className="menu__item">               
                                                            <div className="item__icon">              
                                                                <img src={calendar} alt="img" />           
                                                            </div>            
                                                            <div className="item__text">                
                                                                <h5 className="item__title">Calendar</h5>                
                                                            </div>          
                                                        </Link>         
                                                        <Link to="/AutoTracker" className="menu__item">          
                                                            <div className="item__icon">              
                                                                <img src={autotracker} alt="img" />        
                                                            </div>          
                                                            <div className="item__text">           
                                                                <h5 className="item__title">Auto tracker</h5>                
                                                            </div>      
                                                        </Link>*/}                
                                                    </div>        
	                                            </div>       
                                                <div className="menu__column mw-328 industries">         
                                                    <div className="column-heading">            
                                                        <h5>REPORTING</h5>       
                                                    </div>         
                                                    <div className="menu__items-wrapper">               
                                                        <Link to="/reports" className="menu__item">               
                                                            <div className="item__icon">                
                                                                <img src={reports} alt="img" />      
                                                            </div>           
                                                            <div className="item__text">               
                                                                <h5 className="item__title">Reports</h5>           
                                                            </div>           
                                                        </Link>            
                                                        <Link to="/rates" className="menu__item">              
                                                            <div className="item__icon">               
                                                                <img src={rates} alt="img" />            
                                                            </div>           
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Rates</h5>          
                                                            </div>         
                                                        </Link> 
                                                        {/*         
                                                        <Link to="/projects" className="menu__item">             
                                                            <div className="item__icon">         
                                                                <img src={progress} alt="img" />         
                                                            </div>      
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Projects</h5>            
                                                            </div>         
                                                        </Link>   
                                                        */}       
                                                        <Link to="/features_reporting_activity" className="menu__item">              
                                                            <div className="item__icon">               
                                                                <img src={activity} alt="img" />          
                                                            </div>           
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Activity</h5>         
                                                            </div>    
                                                        </Link> 
                                                        {/*         
                                                        <Link to="/features_reporting_location" className="menu__item">        
                                                            <div className="item__icon">           
                                                                <img src={location} alt="img" />         
                                                            </div>           
                                                            <div className="item__text">              
                                                                <h5 className="item__title">Location</h5>            
                                                            </div>          
                                                        </Link> 
                                                        */}                   
                                                    </div>                       
                                                </div>            
                                                <div className="menu__column mw-233 after-parent">               
                                                    <div className="column-after hidden-sm"></div>              
                                                    <div className="column-heading">                  
                                                        <h5>MANAGEMENT</h5>             
                                                    </div>                 
                                                    <div className="menu__items-wrapper">               
                                                        <Link to="/features_scheduling" className="menu__item">                  
                                                            <div className="item__icon no-hover">               
                                                                <img src={scheduling} alt="img" />             
                                                            </div>                
                                                            <div className="item__text">                   
                                                                <h5 className="item__title">Scheduling</h5>                            
                                                            </div>               
                                                        </Link>              
                                                        <Link to="/features_timeoff" className="menu__item">                   
                                                            <div className="item__icon no-hover">                  
                                                                <img src={timeoff} alt="img" />             
                                                            </div>                 
                                                            <div className="item__text">                  
                                                                <h5 className="item__title">Time off</h5>                         
                                                            </div>               
                                                        </Link>                
                                                        <Link to="/features_approval" className="menu__item">                
                                                            <div className="item__icon no-hover">                
                                                                <img src={approval} alt="img" />        
                                                            </div>             
                                                            <div className="item__text">                
                                                                <h5 className="item__title">Approval</h5>                       
                                                            </div>             
                                                        </Link>              
                                                        <Link to="/features_expense" className="menu__item no-border">                
                                                            <div className="item__icon no-hover">                 
                                                                <img src={expenses} alt="img" />            
                                                            </div>              
                                                            <div className="item__text">                
                                                                <h5 className="item__title">Expenses</h5>              
                                                            </div>            
                                                        </Link>  
                                                        <Link to="/features_invoicing" className="menu__item no-border">                
                                                            <div className="item__icon no-hover">                 
                                                                <img src={invoicing} alt="img" />            
                                                            </div>              
                                                            <div className="item__text">                
                                                                <h5 className="item__title">Invoicing</h5>              
                                                            </div>            
                                                        </Link>            
                                                    </div> 
                                                    <div className="additional-info">                 
                                                        <Link to="/features_seeall_features">See all features<span>→</span></Link>            
                                                    </div>             
                                                </div>              
                                                {/* <div className="bottom-banner hidden-sm">
                                                    <div className="sitewidth">
                                                        <div>
                                                            <Link to="/ContactUs" className="white">
                                                                <img src={megamenu19} alt="img" />
                                                                Contact Us
                                                            </Link>
                                                            <Link to="/product_tutorails" className="white">
                                                                <img src={megamenu20} alt="img" />
                                                                Tutorials
                                                            </Link>
                                                            <Link to="/product_knowledgehub" className="white hidden-1024">
                                                                <img src={megamenu21} alt="img" />
                                                                Knowledge Hub
                                                            </Link>
                                                            <Link to="/product_blog" className="white hidden-1024">
                                                                <img src={megamenu22} alt="img" />
                                                                Blog
                                                            </Link>
                                                            <Link to="/help" className="white">
                                                                <img src={megamenu23} alt="img" />
                                                                Help
                                                            </Link>
                                                        </div>
                                                        <div className="hidden-sm rating-reviews">
                                                            <Link to="/#!" className="white">
                                                                <img src={megamenu24} alt="img" />
                                                                4,000+ Reviews
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>                             */}
	                                            <div className="background-blur"></div>           
                                            </div>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link"  to="https://eclockdashboard.vercel.app/login" target="blank">
                                            Login
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link"  to="https://eclockdashboard.vercel.app/login" target="blank">
                                            Sign Up
                                        </Link>
                                    </li>
                                </ul>
                                <div className="other-option">
                                    <Link className="btn btn-theme" to="/Contact">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="plamb-responsive-nav">
                    <div className="container">
                        <div className="responsive-button" onClick={handleClick}>
                            {click ? <AiOutlineClose/> : <HiMenuAlt3/>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
