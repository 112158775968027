import React from 'react'
import shap from '../assets/img/svg/footer.svg'
import { Link } from 'react-router-dom'
import logo from '../assets/img/logo/logo.png'
import { ImLocation } from 'react-icons/im';
import { FiPhoneOutgoing } from 'react-icons/fi';
import { SiMinutemailer } from 'react-icons/si';
 
const FooterItems=[
    {
        title:"Useful Links",
        links: [
          { linkTitle: "Home", link: "/" },
          { linkTitle: "Faqs", link: "/faqs" },
          { linkTitle: "Plan", link: "/product_seepricingplan" },
        //   { linkTitle: "News", link: "/blog" },
        ],
    },
    {
        title:"Services",
        links: [
          { linkTitle: "About", link: "/about" },
          { linkTitle: "Contact Us", link: "/contact" },
        //   { linkTitle: "Vision, Mission & Values", link: "/vission-mission" },
        // { linkTitle: "Make a company review ", link: "/company-review" },
        ],
    }
]

const Footer=()=> {
 return (
  <>
    <footer id="footer" className="py100">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="footer-about-area">
                        <Link to="#"><img alt="" style={{height: '60px'}} src={logo}  /></Link>
                        {/* <h2><a href="">eClock.Work</a></h2> */}
                        <p className="pt20">
                        <Link to="/vission-mission" >Vision, Mission & Values</Link>
                        </p>
                        <p>
                        <Link to="/company-review" >Make a company review</Link>
                        </p>
                    </div>
                </div>
                {FooterItems.map((item, key)=>(
                     <div className="col-lg-2 col-md-6 col-sm-6 col-6" key={key}>
                           <div className="footer-list-widget pl20 single-item-mt-3 responsive-mt-60 responsive-pl0">
                               <div className="footer-heading">
                                   <h5>{item.title}</h5>
                               </div>
                               <div className="footer-list pt40">
                                   <ul>
                                       {item.links.map((flinks, key)=>(
                                           <li key={key}><Link to={flinks.link}>{flinks.linkTitle}</Link></li>
                                       ))}
                                   </ul>
                               </div>
                           </div>
                        </div>
                ))}
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="footer-list-widget pl20 responsive-mt-60 responsive-pl0">
                        <div className="footer-heading">
                            <h5>Contact Info</h5>
                        </div>
                        <div className="footer-contact-area footer-list pt40">
                           <ul>
                               <li>
                                   <i><ImLocation/></i> 7920 Belt Line Road, Suite 720, Dallas, TX, 75245
                               </li>
                               <li>
                                   <i><FiPhoneOutgoing/></i><Link to="tel:+18005701492">+1 800 5701 492</Link>
                               </li>
                               <li>
                                   <i><SiMinutemailer/></i><Link to="mailto:support@eclock.work">support@eclock.work</Link>
                               </li>
                           </ul>
                       </div>  
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom-img">
            <img alt="" src={shap}  />
        </div>
    </footer>
  </>
 )
}

export default Footer
